import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   // base: process.env.BASE_URL,
   routes: [
      {
         path: '/',
         name: '',
         component: () => import(/* webpackChunkName: "Home" */ '../views/Dash.vue'),
         children: [
            {
               path: '/',
               name: 'home',
               component: () => import(/* webpackChunkName: "Home" */ '../views/Login/Login.vue'),
            },
         ]
      },
      { path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
      },
   ]
})

export default router
