import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/index'

import './assets/css/main.scss'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import 'aos/dist/aos.css'
import {sync} from 'vuex-router-sync'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'



const vuetify = createVuetify({
  components,
  directives,
})


const app = createApp(App)
sync(store, router)
app.use(router)
app.use(vuetify)
app.mount('#app')
